<template>
    <div>
        <div v-if="authUserPermission['mcr-report-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6 header-body">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('mcr_report')}}</h3>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" @click="filterModal">{{ tt('filter') }}</base-button>
                                <a :href="apiUrl+'McrReportLeadTime/export?token='+token" class="btn btn-sm btn-outline-dark" v-if="authUserPermission['kpi-mcr-report-export']">
                                    {{ tt('export_as_excel') }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive header-fixed" style="height:500px" v-if="!table.onLoad">
                        <table class="table">
                            <thead class="thead-light">
                                <tr>
                                    <th>{{ tt('mcr_number')}}</th>
                                    <th>{{ tt('material_number')}}</th>
                                    <th>{{ tt('name')}}</th>
                                    <th>{{ tt('company')}}</th>
                                    <th>{{ tt('approval_status')}}</th>
                                    <th>{{ tt('status')}}</th>
                                    <th>{{ tt('assigned_date')}}</th>
                                    <th>{{ tt('executed_at')}}</th>
                                    <th>{{ tt('duration')}}</th>
                                </tr>
                            </thead>
                            <tr v-for="d in table.data">
                                <td>{{ d.mcr_number }}</td>
                                <td> <span v-for="mn in d.material_number">{{ mn.material_number }} <br></span></td>
                                <td>{{ d.name }}</td>
                                <td>{{ d.company_description }}</td>
                                <td>{{ d.approval_status }}</td>
                                <td>
                                    <div :class="{'badge badge-success': d.status === 'Approved', 'badge badge-info': d.status === 'On process','badge badge-danger': d.status === 'Rejected'}">{{ d.status }}</div></td>
                                <td>{{ moment(d.assigned_date).locale('id').format('LL') }}</td>
                                <td>{{ moment(d.executed_date).locale('id').format('LL') }}</td>
                                <td>{{ d.duration }} days</td>
                            </tr>
                        </table>
                    </div>
                    <skeleton-loading v-else></skeleton-loading>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage()" size="sm"></base-pagination>
                        </span>                             
                    </div>
                </div>
            </div>

            <modal :show.sync="filter.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('filter') }}</h5>
                </template>

                <div>
                    <!-- MCR NUMBER -->
                    <label for="" class="form-control-label">{{ tt('mcr_number') }}</label>
                    <base-input :name="tt('mcr_number')" :placeholder="tt('mcr_number_example')" v-model="filter.data.mcr_number"></base-input>
                    <!-- NAME -->
                    <label for="" class="form-control-label">{{ tt('name') }}</label>
                    <base-input :name="tt('name')">
                        <el-select v-model="filter.data.name" :placeholder="tt('name')" :remote-method="searchName" :loading="filter.onLoad" filterable remote reserve-keyword clearable>
                            <el-option v-for="ub in names" :key="ub.namecode" :label="ub.namecode +' - '+ ub.name" :value="ub.namecode"></el-option>
                        </el-select>
                    </base-input>
                    <!-- COMPANY -->
                    <label for="" class="form-control-label">{{ tt('company') }}</label>
                    <base-input :name="tt('company')">
                        <el-select v-model="filter.data.company" :placeholder="tt('company_example')" clearable filterable remote :remote-method="searchCompany" :loading="filter.onLoad">
                            <el-option v-for="c in companies" :value="c.company_code" :label="c.company_description" :key="c.company_code"></el-option>
                        </el-select>
                    </base-input>
                    <!-- ACTION -->
                    <label class="form-control-label">{{ tt('approval_status') }}</label>
                    <base-input :name="tt('approval_status')">
                        <el-select class="select-danger" v-model="filter.data.approval_status" :placeholder="tt('choose_approval_status')" clearable filterable>
                            <el-option class="select-danger" v-for="data in dataTransactionLog" :value="data.message" :label="data.message"></el-option>
                        </el-select>
                    </base-input>
                    <!-- STATUS -->
                    <label for="" class="form-control-label">{{ tt('status') }}</label>
                    <base-input :name="tt('status')">
                        <el-select v-model="filter.data.status" :placeholder="tt('status')" clearable>
                            <el-option value="On process" label="On process"></el-option>
                            <el-option value="Approved" label="Approved"></el-option>
                            <el-option value="Rejected" label="Rejected"></el-option>
                        </el-select>
                    </base-input>
                    <!-- ASSIGNED DATE -->
                    <label for="" class="form-control-label">{{ tt('assigned_date') }}</label>
                    <base-input :name="tt('asigned_date')" type="date" v-model="filter.data.assigned_date"></base-input>
                    <!-- EXECUTED AT -->
                    <label for="" class="form-control-label">{{ tt('executed_date') }}</label>
                    <base-input :name="tt('executed_date')">
                        <base-input :name="tt('executed_at')" type="date" v-model="filter.data.executed_date"></base-input>
                    </base-input>
                    <!-- DURATION -->
                    <label for="" class="form-control-label">{{ tt('duration') }}</label>
                    <base-input :name="tt('duration')" type="number" v-model="filter.data.duration"></base-input>
                </div>

                <template slot="footer">
                    <base-button type="secondary" @click="filterModal">{{ tt('close')}}</base-button>
                    <base-button type="primary" @click="filterAction">
                        <span v-if="filter.btnLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            {{ tt('filter') }}
                        </span>
                    </base-button>
                </template>
            </modal>
        </div>
        <noaccess v-else></noaccess>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Api from '@/helpers/api';
import McrReportLeadTime from '@/services/report/mcrReportLeadTime.service';
import config from '@/configs/config';
import companyCode from '@/services/master/companyCode.service';
import AccountService from '@/services/manage/manageAccount.service';
import transactionLog from '@/services/report/transactionLog.service';

var moment = require('moment');

export default {
    data(){
        return{
            moment:moment,
            table:{
                data:[],
                page:{},
                onLoad:false,
            },
            filter:{
                    show:false,
                    data:{
                        mcr_number:'',
                        material_number:'',
                        name:'',
                        company:'',
                        approval_status:'',
                        status:'',
                        assigned_date:'',
                        executed_date:'',
                        duration:null,
                    },
                    onLoad:false,
                    btnLoading:false,
            },
            companies:[],
            names:[],
            dataTransactionLog:[],
            apiUrl: config.apiUrl,
		    token: localStorage.getItem('token')
        }
    },
    computed: {
        ...mapState({
            authUserPermission :state => state.auth.userPermission
        }),
    },
    mounted(){
        this.get()
        this.transactionLog()
    },
    methods:{
        get(){
            let context = this;
            this.table.onLoad = true;

            let query = (this.$route.query != null) ? this.$route.query : this.filter.data

            Api(context, McrReportLeadTime.get(this.table.current_page,query)).onSuccess((response) => {
                console.log('McrReportLeadTime', response.data.data)
                context.table.data              = response.data.data.dataMaterial
                context.table.page.last_page    = response.data.data.data.last_page
                context.table.page.current_page = response.data.data.data.current_page
                context.table.page.per_page     = response.data.data.data.per_page
                context.table.page.total        = response.data.data.data.total
            }).onError((e) => {
                context.table.data = []
            }).onFinish(() => {
                context.table.onLoad = false;
            }).call()
        },
        countDays(executed, assigned){
            if (executed) {
                var a = moment(executed);
                var b = moment(assigned);
                return a.diff(b, 'days') + ' days'
            }
        },
        changePage(){
            setTimeout(() => {
                this.get()
            },100)
        },
        filterModal(){
            this.filter.show = !this.filter.show;
        },
        searchCompany(query){
            let context = this;
            this.filter.onLoad = true;
            Api(context, companyCode.get(null, {per_page:'none', search:query})).onSuccess((response) =>{
                context.companies = response.data.data.data.data;
                context.filter.onLoad = false;
            }).onError((e) =>{
                context.companies = [];
            }).call()
        },
        filterAction(){
            this.filter.btnLoading = true
            setTimeout(() => {
                this.filter.show = false;
                this.filter.btnLoading = false;
                this.$router.replace({query: this.filter.data})
                this.get();
            }, 300)
        },
        searchName(query) {
            if (query.length > 1) {         
                let context = this;   
                this.filter.onLoad = true;
                
                setTimeout(() => {
                    Api(context, AccountService.get(null, {per_page: 'none',name: query})).onSuccess(function(response) {
                        context.names = response.data.data.data.data;                                        
                    }).onError(function(error) {
                        context.names = [];
                    }).onFinish(() => {
                        context.filter.onLoad = false;
                    }).call();             
                }, 200)
            }
        },
        transactionLog() {
            let context = this;
            Api(context, transactionLog.index({date:'', search:'approval_action'})).onSuccess(function(response) {
                const filterData = [...new Map(response.data.data.data.map(item =>[item['message'], item])).values()];
                context.dataTransactionLog = filterData
            }).onError(function(error) {         
                context.dataTransactionLog = []
            }).call();
        },
    }
}
</script>